/* queryBuilder */
.group {
  background: #F4F6F8  !important;
  border: 1px solid var(--primary-color) !important;
}

.group--children > .group-or-rule-container > .group-or-rule::before, .group--children > .group-or-rule-container > .group-or-rule::after{
  width: 10px !important;
}

.query-builder * {
  box-sizing: unset !important;
}

.group--actions {
  opacity: unset !important;
}

.rule--header {
  opacity: unset !important;

}