.eg-dark-theme {
  .fr-wrapper {
    background-color: #333;
    color: #fff;

    .fr-view {
      background-color: #333;
      color: #fff;
      border-color: #444;

      &:focus {
        border-color: #555;
      }
    }
  }

  .fr-toolbar {
    background-color: #222;

    .fr-command {
      color: #fff;
    }

    .fr-dropdown-menu {
      background-color: #222;
      color: #fff;
    }

    .fr-btn {
      color: #fff;
    }
  }
}