/**
SMACSS http://smacss.com/
Base rules are the defaults. They are almost exclusively single element selectors but it could include attribute selectors, pseudo-class selectors, child selectors or sibling selectors. Essentially, a base style says that wherever this element is on the page, it should look like this.
*/

:root {
  --primary-color: #06a6de;
  --secondary-color: #ffffff;
  --header-height: 50px;
  --repertory-color: #E94FB2;

}